.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__button {
  padding: 0 0.5rem !important;
}

.react-calendar__month-view__weekdays {
  font-size: 0.65em !important;
}

.calendar--custom {
  margin-top: 10px !important;
  border: none !important;
  border-radius: 20px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  padding: 10px !important;
  font-size: inherit !important;
  font-family: inherit !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-date-picker__calendar {
  z-index: 2;
}
