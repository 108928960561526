.quill {
  display: flex;
  flex-direction: column;
  min-height: 200px;

  border-radius: var(--bs-border-radius);
  background-color: white;
}

.ql-toolbar {
  border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
}

.ql-toolbar :before {
  font-family: 'DniproCity', sans-serif;
  font-size: 18px;
  font-weight: normal;
}

.ql-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  font-family: 'DniproCity', sans-serif;
  font-size: 20px;
  border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
}

.ql-editor {
  flex: 1;
}
