.timeline-page-progress-bar {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;

  height: 20px;
  /* when make linear-gradient it should be not background-color
  byt background and it causes weird bug */
  background-color: rgb(224, 39, 39);
  transform-origin: 0%;
}