.zero-height {
  height: 0px;
}

.table-image {
  width: 50px;
  min-width: 50px;
  height: 50px;
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-blue {
  background-color: rgb(216, 237, 255);
}

.bg-blue:hover {
  background-color: rgb(199, 230, 255);
}

.bg-grey {
  background-color: #6a6a6a;
}

.bg-grey:hover {
  background-color: #5a5959;
}

.bg-cool {
  background-color: #e1e1e1;
}

.bg-red {
  background-color: rgb(224, 39, 39);
}

.bg-red:hover {
  background-color: rgb(190, 42, 42);
}

.mt-n1 {
  margin-top: -1.25rem;
}

@media (min-width: 1200px) {
  .mt-xl-n1 {
    margin-top: -1.25rem;
  }
}

.shadow-extra {
  box-shadow: 0 4px 4px rgba(0,0,0,.25);
}

.pb-6 {
  padding-bottom: 4rem;
}
