.timeline-card-image {
  width: 300px;
  max-width: 300px;
  min-height: 200px;
  max-height: 400px;

  object-fit: cover;
  border-radius: 25px;
}

.timeline-card-line::after {
  position: absolute;
  top: 45px;
  bottom: 0;

  content: "";
  width: 2px;
  
  background: linear-gradient(
    180deg,
    rgba(80, 80, 80, 0) 0,
    #505050 8%,
    #505050 100%,
    rgba(80, 80, 80, 0) 0
  );
}

.timeline-card-icon-container {
  height: 48px;
  width: 48px;
}

.timeline-card-icon {
  height: 36px;
  width: 36px;
}

@media (max-width: 767px) {
  .timeline-card-date-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  .timeline-card-image {
    width: 300px;
    max-width: 300px;
  }
}

@media (max-width: 470px) {
  .timeline-card-image {
    max-width: 80%;
  }

  .timeline-card-icon-container {
    height: 32px;
    width: 32px;
  }

  .timeline-card-icon {
    height: 28px;
    width: 28px;
  }
}

@media (min-width: 768px) {
  .timeline-card-date {
    font-size: 2.25rem;
    line-height: 0.9;
  }
  
  .timeline-card-year {
    font-size: 90px;
    line-height: 0.9;
  }
}

.timeline-card-collapse {
  display: grid;
  grid-template-rows: 0fr;

  overflow: hidden;
  transition: grid-template-rows 0.7s ease;
}

.timeline-card-collapse > * {
  min-height: 0;
}

.timeline-card-collapse.opened {
  grid-template-rows: 1fr;
}
