.public-victim-item__container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;

    border: 2px solid transparent;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.public-victim-item__lazy-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.public-victim-item__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.public-victim-item__container:hover {
    border-color: rgb(190, 42, 42);
    box-shadow: 0 0 10px rgb(190, 42, 42);
}

.medal {
    position: absolute;
    bottom: -10px;
    right: -10px;

    background: rgba(255, 255, 255, 0.24);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.medal2 {
    position: absolute;
    bottom: -30px;
    right: -30px;
    width: 100px;
    height: 100px;

    background: rgba(255, 255, 255, 0.24);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

@media only screen and (max-width: 993px) {
    .medal2 {
        bottom: -10px;
        right: 30px;
        width: 70px;
        height: 70px;
    }
}

.medal2 img{
    width: 100%;
}
