.flag-icon {
  width: 36px;
  height: 36px;
}

@media (max-width: 640px) {
  .flag-icon {
    width: 24px;
    height: 24px;
  }
}

.switch-language-button-light {
  color: #c7c7c7;
}

.switch-language-button-light:hover {
  color: #f0f0f0;
}

.switch-language-button-dark {
  color: #222222;
}

.switch-language-button-dark--selected {
  color: #707070;
}