.victim-info-page-image {
  min-width: 60%;
  max-width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 25px;
}

.victim-info-page-container {
  padding: 0 20px;
}

@media (max-width: 576px) {
  .victim-info-page-title {
    font-size: 22px !important;
  }
}

@media (min-width: 768px) {
  .victim-info-page-container {
    padding: 0 60px;
  }
  
  .victim-info-page-image {
    max-height: 400px;
  }
}

@media (min-width: 1200px) {
  .victim-info-page-container {
    padding: 0 100px;
  }
}

@media (min-width: 1400px) {
  .victim-info-page-image {
    width: 100%;
    max-height: 500px;
  }
}

@media (min-width: 1600px) {
  .victim-info-page-container {
    padding: 0 200px;
  }
}


