.color-picker__color {
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  transition: box-shadow .3s;
  border-radius: 50%;
}

.color-picker__color--selected {
  border: 2px black solid;
}

.color-picker__color:hover {
  box-shadow: 0 0 10px 5px rgba(0,0,0,.2);
  cursor: pointer;
}

.color-picker__color:not(:last-child) {
  margin-right: 10px;
}