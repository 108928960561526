@font-face {
  font-family: 'DniproCity';
  src: url('../assets/fonts/DNIPROCITY-REGULAR.OTF') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DniproCity';
  src: url('../assets/fonts/DNIPROCITY-MEDIUM.OTF') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DniproCity';
  src: url('../assets/fonts/DNIPROCITY-BOLD.OTF') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DniproCity';
  src: url('../assets/fonts/DNIPROCITY-BLACK.OTF') format('opentype');
  font-weight: 800;
  font-style: normal;
}
