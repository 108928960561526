.filepond--credits {
  display: none;
}

.filepond--root {
  font-family: 'DniproCity', sans-serif !important;
  margin-bottom: 0px;
}

.filepond--root,
.filepond--root .filepond--drop-label {
  min-height: 200px !important;
  border-radius: var(--bs-border-radius) !important;
}

.filepond--white.filepond--root,
.filepond--white.filepond--root .filepond--drop-label {
  background-color: white;
}

.filepond--image-preview-overlay-idle {
  display: none !important;
}

.filepond--action-retry-item-processing {
  /* display: none !important; */
  transform: translateY(30px) !important;
}
